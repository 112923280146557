import { UserIcon } from '@heroicons/vue/20/solid'
import type { User } from '~/repository/modules'
import { VBadge } from '#components'

export function useAuthUserHelpers() {
  const renderUserBadge = (user: User) => {
    return h(VBadge, { type: 'info', class: 'whitespace-nowrap' }, () => [
      h(UserIcon, { class: 'h-3 h-3' }),
      h('span', user.full_name),
    ])
  }

  return {
    renderUserBadge,
  }
}
